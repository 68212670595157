
<template>
  <div class="BottomPane"  :id="id">
    <div class="content">
      <slot></slot>
    </div>

  </div>
</template>

<script>
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

export default {
  name: 'BottomPane',
  mounted() {
    console.log(this.id)

/*     console.log(this.toggleShow)
    console.log(this.$store.state.showBottomPane)
    console.log('#' + this.paneid)
    console.log(document.querySelector('#' + this.paneid)
    ) */
    this.$nextTick(() => {
      this.setPane(this.id)
     // this.togglePane = true;
   //   console.log(this.togglePane)
/*       this.pane = new CupertinoPane(
        '#' + this.id + '.BottomPane', // Pane container selector
        {
          parentElement: '#app', // Parent container
        }
      ); */
      //this.$store.commit('panes/setPane', { id: this.id })
      //this.pane.present({ animate: true }).then(res => { });
  /*     if (this.$store.state.showBottomPane[this.paneid].value === true) {
        console.log("SHOWWW")
        this.pane.present({ animate: true }).then(res => { });
      }


      this.pane.on('onDidDismiss', () => {
        console.log('dismiss')
        // this.$store.state.toggleBottomPane =false;
        this.$store.commit('toggleBottomPane', { id: this.paneid, val: false })
 
      }); */

    })
  },
  data() {
    return {
      pane: null
    }
  },
  props: {
    id:String
/*     toggleShow: Boolean,
    paneid: String */
  },
  methods: {

  },
  computed: {
/*     togglePane: {
      get() {
        return this.$store.state.panes.elements[this.id] 
      },
      set(value) {
        
        this.$store.commit('panes/setPane', { id: this.id, val: value })
      }
    } */
  },
  watch: {
 /*    togglePane: function (newVal, oldVal) { // watch it
      console.log(newVal,oldVal)
    }, deep: true */
  },
}
</script>

<style lang="scss" scoped>
#editRecipe {
    overflow: scroll auto!important;
}
.miniPaneContainer {
  padding: 12px 32px
}

.content {
  padding: 24px;
}
</style>

